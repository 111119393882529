import { combineReducers } from "redux";
import { appReducer } from "./app";
import { commonReducer } from "./common";
import { newsReducer } from "./news";

export const rootReducer = combineReducers({
  app: appReducer,
  news: newsReducer,
  common: commonReducer,
});
