/* eslint-disable @typescript-eslint/no-explicit-any */
// Describing the shape of the session's slice of state
export class AccountSession {
  userName: string;
  email: string;
  isLogin: boolean;
  token: string;
  idToken: string;
  expireTime: number;
  refreshToken: string;

  constructor() {
    this.userName = '';
    this.email = '';
    this.isLogin = false;
    this.token = '';
    this.idToken = '';
    this.expireTime = 0;
    this.refreshToken = '';
  }
}
export interface AppStates {
  sidebarShow: any;
  accountSession: AccountSession;
  language: any | undefined;
}

export class HeaderLogoItem {
  url: string;

  constructor() {
    this.url = '';
  }
}

export class HeaderAlternativeLogoItem {
  url: string;

  constructor() {
    this.url = '';
  }
}
export class FooterLogoItem {
  url: string;

  constructor() {
    this.url = '';
  }
}

export interface GetHomeDataRequest {
  wpml_language: string;
}

// Describing the different ACTION NAMES available
export const SET_ACCOUNT_SESSION = 'SET_ACCOUNT_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const GET_GLOBAL_DATA_SUCCESS = 'GET_GLOBAL_DATA_SUCCESS';

export const GET_LANGUAGE = 'GET_LANGUAGE';
export interface AccountSetSessionAction {
  type: typeof SET_ACCOUNT_SESSION;
  payload: AccountSession;
}

export interface ClearSession {
  type: typeof CLEAR_SESSION;
}

export interface GetLanguage {
  type: typeof GET_LANGUAGE;
  payload: any;
}

export type AppActionTypes = AccountSetSessionAction | ClearSession | GetLanguage;
