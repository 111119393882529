import {
  CLEAR_NEWS_DETAIL,
  GET_HIGHT_LIGHT_NEWS,
  GET_HOST_NEWS,
  GET_LATEST_NEWS,
  GET_LIST_NEWS,
  GET_NEWS_DETAILS,
  NewsActionTypes,
  NewsDetailsItemVM,
  NewsItemVM,
  NewsStates,
} from './types';

const initialState: NewsStates = {
  listNews: [],
  latestNews: new NewsItemVM(),
  hightLightNews: [],
  newsDetails: new NewsDetailsItemVM(),
  hostNew: [],
};

export const newsReducer = (state = initialState, action: NewsActionTypes): NewsStates => {
  switch (action.type) {
    case GET_LIST_NEWS: {
      const { listNews } = action.payload;
      return {
        ...state,
        listNews: listNews,
      };
    }
    case GET_LATEST_NEWS: {
      const { latestNews } = action.payload;
      return {
        ...state,
        latestNews: latestNews,
      };
    }
    case GET_HIGHT_LIGHT_NEWS: {
      const { hightLightNews } = action.payload;
      return {
        ...state,
        hightLightNews: hightLightNews,
      };
    }
    case GET_NEWS_DETAILS: {
      return {
        ...state,
        newsDetails: action.payload,
      };
    }
    case CLEAR_NEWS_DETAIL: {
      return {
        ...state,
        newsDetails: new NewsDetailsItemVM(),
      };
    }
    
    case GET_HOST_NEWS: {
      return {
        ...state,
        hostNew: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
