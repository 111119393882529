import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isEmpty } from './utils';


// const common = 
const backendOptions = isEmpty(JSON.parse(localStorage.getItem('translations'))) ? {

  loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
} : {
  request: (options, url, payload, callback) => {
    const res = JSON.parse(localStorage.getItem('translations') || '{}');
    callback(null, {
      data: res,
      status: 200,
    });
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng') || 'en',
    debug: false,
    ns: [
      'common',
      'home',
      'about-us',
      'ai-solutions',
      'contact-us',
      'medical-community',
      'pharmas-cros',
      'news',
      'healthcare-providers',
    ],

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export const onSetDataTranslate = (res) => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        request: (options, url, payload, callback) => {
          callback(null, {
            data: res,
            status: 200,
          });
        },
      },
      fallbackLng: 'en',
      lng: localStorage.getItem('i18nextLng') || 'en',
      load: 'languageOnly',
      debug: false,
      ns: ['translations'],
  

      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        wait: true,
      },
    });
};

export default i18n;
