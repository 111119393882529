import {
  AppStates,
  AppActionTypes,
  GlobalDataVM,
  GET_GLOBAL_DATA_SUCCESS,
  SET_ENABLED_SPINNER,
  SET_DOCUMENTS,
} from './types';

const initialState: AppStates = {
  globalData: new GlobalDataVM(),
  isEnabledSpinner: false,
  documents: [],
};

export const commonReducer = (state = initialState, action: AppActionTypes): AppStates => {
  switch (action.type) {
    case GET_GLOBAL_DATA_SUCCESS: {
      return {
        ...state,
        globalData: action.payload,
      };
    }

    case SET_ENABLED_SPINNER: {
      return {
        ...state,
        isEnabledSpinner: action.payload,
      };
    }

    case SET_DOCUMENTS: {
      return {
        ...state,
        documents: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
