import {
  AppStates,
  AppActionTypes,
  SET_ACCOUNT_SESSION,
  AccountSession,
  CLEAR_SESSION,
  GET_LANGUAGE,
} from './types';

const initialState: AppStates = {
  sidebarShow: 'responsive',
  accountSession: new AccountSession(),
  language: undefined,
};

export const appReducer = (state = initialState, action: AppActionTypes): AppStates => {
  switch (action.type) {
    case SET_ACCOUNT_SESSION: {
      return {
        ...state,
        accountSession: action.payload,
      };
    }

    case CLEAR_SESSION: {
      return {
        ...initialState,
      };
    }

    case GET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
