import { NewVM } from '@/core/models/news.model';

// Describing the shape of the session's slice of state
export class NewsItemVM {
  postId?: number;
  postTitle?: string;
  postSlug?: string;
  intro?: string;
  postPublishedDate?: string;
  postAuthor?: string;
  thumbnailImage?: ThumbnailImageVM;
  categories?: CategoryVM[];
  externalLink?: string;

  constructor() {
    this.postId = 0;
    this.postTitle = '';
    this.postSlug = '';
    this.intro = '';
    this.postPublishedDate = '';
    this.postAuthor = '';
    this.thumbnailImage = { url: '' };
    this.categories = [];
    this.externalLink = '';
  }
}

export class NewsDetailsItemVM {
  postId?: string;
  postTitle?: string;
  postSlug?: string;
  intro?: string;
  postPublishedDate?: string;
  postAuthor?: string;
  postContent?: string;
  postModifiedDate?: string;
  thumbnailImage?: ThumbnailImageVM;
  categories?: CategoryVM[];
  externalLink?: string;

  constructor() {
    this.postId = '';
    this.postTitle = '';
    this.postSlug = '';
    this.intro = '';
    this.postPublishedDate = '';
    this.postAuthor = '';
    this.postContent = '';
    this.postModifiedDate = '';
    this.thumbnailImage = { url: '' };
    this.categories = [];
    this.externalLink = '';
  }
}

interface ThumbnailImageVM {
  url: string;
}

interface CategoryVM {
  termId: number;
  name: string;
  slug: string;
}

export class CategoryItemVM {
  termId: number;
  name: string;
  slug: string;
  children?: CategoryVM[];

  constructor() {
    this.termId = 0;
    this.name = '';
    this.slug = '';
    this.children = [];
  }
}

export interface NewsStates {
  listNews: NewsItemVM[];
  latestNews: NewsItemVM;
  hightLightNews: NewsItemVM[];
  newsDetails: NewsDetailsItemVM;
  hostNew: NewVM[];
}

// Describing the different ACTION NAMES available
export const GET_LIST_NEWS = 'GET_LIST_NEWS';
export const GET_LATEST_NEWS = 'GET_LATEST_NEWS';
export const GET_HIGHT_LIGHT_NEWS = 'GET_HIGHT_LIGHT_NEWS';
export const GET_NEWS_DETAILS = 'GET_NEWS_DETAILS';
export const CLEAR_NEWS_DETAIL = 'CLEAR_NEWS_DETAIL';
export const GET_HOST_NEWS = 'GET_HOST_NEWS';

//payload type

export type GetLatestNewsPayload = {
  latestNews: NewsItemVM;
};

export type GetHightLightNewsPayload = {
  hightLightNews: NewsItemVM[];
};
export type GetNewsPayload = {
  listNews: NewsItemVM[];
};
export interface GetNewsAction {
  type: typeof GET_LIST_NEWS;
  payload: GetNewsPayload;
}

export interface GetLatestNewsAction {
  type: typeof GET_LATEST_NEWS;
  payload: GetLatestNewsPayload;
}

export interface GetHightLightNewsAction {
  type: typeof GET_HIGHT_LIGHT_NEWS;
  payload: GetHightLightNewsPayload;
}

export interface GetNewsDetaislAction {
  type: typeof GET_NEWS_DETAILS;
  payload: NewsDetailsItemVM;
}

export interface ClearNewsDetailAction {
  type: typeof CLEAR_NEWS_DETAIL;
}

export interface GetHostNewAction {
  type: typeof GET_HOST_NEWS;
  payload: NewVM[];
}

export type NewsActionTypes =
  | GetNewsAction
  | GetLatestNewsAction
  | GetNewsDetaislAction
  | GetHightLightNewsAction
  | GetNewsAction
  | GetHostNewAction
  | ClearNewsDetailAction;
