/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../src/assets/css/pages/app.css';

const MainLayout: any = React.lazy(() => import('./components/layout/main-layout'));

// Containers
const loading = (
  <div className={'container-loading'}>
    <div className={'loader'} />
  </div>
);

const App: React.FC = () => {
  
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path={'/'} render={(props) => <MainLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
