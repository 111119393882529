import { NewVM } from '@/core/models/news.model';
import { DocumentsModel } from '../../../../core/models/common.models';

export interface AppStates {
  globalData: GlobalDataVM;
  isEnabledSpinner: boolean;
  documents: NewVM[];
}

export class ImageItem {
  url: string;

  constructor() {
    this.url = '';
  }
}

export class GlobalDataVM {
  address1: string;
  address2: string;
  address3: string;
  footerCopyright: string;
  email: string;
  phone: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  youtube: string;
  headerLogo: ImageItem;
  footerLogo: ImageItem;
  headerAlternativeLogo: ImageItem;
  linkBelleProAppStore: string;
  linkBelleProGooglePlay: string;
  faxNumber: string | undefined;

  constructor() {
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.email = '';
    this.facebook = '';
    this.footerCopyright = '';
    this.phone = '';
    this.twitter = '';
    this.linkedin = '';
    this.youtube = '';
    this.headerLogo = new ImageItem();
    this.footerLogo = new ImageItem();
    this.headerAlternativeLogo = new ImageItem();
    this.linkBelleProAppStore = '';
    this.linkBelleProGooglePlay = '';
    this.faxNumber = undefined;
  }
}

// Describing the different ACTION NAMES available
export const GET_GLOBAL_DATA_SUCCESS = 'GET_GLOBAL_DATA_SUCCESS';
export const SET_ENABLED_SPINNER = 'SET_ENABLED_SPINNER';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';

export interface SpinnerSetEnabledAction {
  type: typeof SET_ENABLED_SPINNER;
  payload: boolean;
}

export interface GetGlobalDataSuccessAction {
  type: typeof GET_GLOBAL_DATA_SUCCESS;
  payload: GlobalDataVM;
}
export interface SetDocumentsSuccessAction {
  type: typeof SET_DOCUMENTS;
  payload: NewVM[];
}

export type AppActionTypes =
  | GetGlobalDataSuccessAction
  | SpinnerSetEnabledAction
  | SetDocumentsSuccessAction;
