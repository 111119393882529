import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// Import Swiper styles
import 'swiper/css';

import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './core/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import './polyfill';
import './core/lib/i18n';

const loading = <div className={'pt-3 text-center'}>Loading...</div>;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={loading} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
