import { DocumentTypeEnum } from "./constants";

export const example = (): boolean => {
  return true;
};

export const isEmpty = (value: unknown): boolean => {
  return value === undefined || value === '' || value === null;
};

export const getCurrentPathName = (): string => {
  return window.location.pathname || '';
};

export const getCurrentDomainName = (): string => {
  const url = window.location.href;
  const splitUrl = url.split('/');
  return `${splitUrl[0]}//${splitUrl[2]}`;
};

export const getAccessToken = (): string => {
  return localStorage.getItem('belle-widget-access-token') || '';
};

export const clearPersistData = (): void => {
  localStorage.removeItem('persist:root');
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem('belle-widget-access-token', token);
};

export const getCognitoConfig = (): any => {
  const configCognitoDataDefault = {
    region: 'us-east-1',
    userPool: '',
    userPoolBaseUri: '',
    clientId: '',
    callbackUri: `${getCurrentDomainName()}/logincallback`,
    signoutUri: `${getCurrentDomainName()}/logoutcallback`,
    tokenScopes: ['openid', 'email', 'profile'],
    apiUri: '',
  };
  const cognitoConfig = localStorage.getItem('cognito-config');
  if (
    cognitoConfig !== undefined &&
    cognitoConfig !== '' &&
    cognitoConfig !== null
  ) {
    return cognitoConfig;
  }
  return JSON.stringify(configCognitoDataDefault);
};

export const checkIsLoggedIn = (): boolean => {
  if (
    getAccessToken() !== undefined &&
    getAccessToken() !== null &&
    getAccessToken() !== ''
  )
    return true;
  return false;
};

export const setCurrentPage = (pageSlug: string): void => {
  localStorage.setItem('currentPage', pageSlug);
};

export const getCurrentPage = (): string => {
  return localStorage.getItem('currentPage') || '';
};

export const getIdParam = (): string => {
  const splitPath = window.location.href.split('=');
  return (splitPath[splitPath.length - 1]);
}
export const convertDate = (dates: string): any =>{
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

  const date = new Date(dates || Date.now())
  const day = (date.getDay())
  const month = (monthNames[date.getMonth()])
  const year = (date.getFullYear())
  return ( `${month} ${day}, ${year}`)
}

export const getDocumentName = (url: string) => {
const temp = url.split('/');
return temp[temp.length -1];
};
